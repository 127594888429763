<template>
    <el-dialog
        custom-class="log-info" title="日志" append-to-body
        :close-on-click-modal="false"
        :visible.sync="showDialog" width="800px" style="min-height: 528px;"
    >

        <div class="content">
            <p v-for="item in info">
                [{{ item.time }}]
                &nbsp;&nbsp;
                {{ item.content }}
            </p>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose" type="primary">关闭</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    name: "log-info",
    data () {
        return {
            showDialog: false,
            rfInfo: '',
            info: [
            ]
        }
    },
    methods: {
        show (row) {
            this.showDialog = true
            this.rfInfo = row
            this.getData()
        },
        getData () {
            this.$get('/rf/content/' + this.rfInfo.rfid)
            .then(res => {
                if (res.code === '200') {
                    this.info = res.data
                }
            })
        },
        handleClose () {
            this.showDialog = false
        },
    }
}
</script>

<style scoped lang="scss">


</style>