<template>
    <el-dialog
        custom-class="task-form" title="新建离线盘匣任务" append-to-body
        :close-on-click-modal="false"
        :visible.sync="showDialog" width="800px" style="min-height: 528px;"
    >
        <el-form ref="form" label-width="100px" :model="model">
            <el-form-item label="任务名称" prop="name"
                          :rules="[
                                {required:true, message:'请填写任务名称！'},
                                {max: 20, message: '最多输入20个字符'},
                          ]"
            >
                <el-input v-model.trim="model.name"/>
            </el-form-item>
            <el-form-item label="桶" prop="bucket" :rules="{required:true, message:'请选择桶！'}">
                <el-select
                    size="small"
                    style="width: 300px;"
                    @change="bucketChecked"
                    v-model="model.bucket">
                    <el-option v-for="s in bucketList" :key="s.name" :label="s.name" :value="s.name"/>
                </el-select>
            </el-form-item>
            <el-form-item label="文件路径" prop="fileList" >
                <el-cascader
                    size="small"
                    style="width: 300px;"
                    ref="cascaderRef"
                    :options="options"
                    :props="props"
                    collapse-tags
                    @change="handleCascaderChange"
                    clearable></el-cascader>
            </el-form-item>
            <el-form-item label="文件路径列表" prop="keys" >
                <el-input type="textarea" :rows="6" :value="keyList" disabled/>
            </el-form-item>
            <el-form-item label="任务说明" prop="remark" :rules="[{required:true, message:'请填写任务说明！'}, {max: 100, message: '输入字符不能超过100个'}]">
                <el-input type="textarea" :rows="5" v-model.trim="model.remark" maxlength="100"
                          show-word-limit/>
            </el-form-item>

        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">取消</el-button>
            <el-button :loading="loading" size="small" @click="handleSubmit" type="primary">确定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    name: "taskForm",
    data () {
        return {
            showDialog: false,
            model: {
                name: '',
                bucket: '',
                keys: [],
                remark: ''
            },
            loading: false,
            options: [],
            regionList: [],
            regionId: '',
            bucketList: [],
            filePathList: [],
            props: {multiple: true}
        }
    },
    computed: {
        keyList () {
            return this.model.keys.join('\r\n')
        }
    },
    mounted() {
        this.getRegionList()
    },
    methods: {
        getRegionList() {
            this.loadingData = true
            this.$post('/region/listPurchased', {})
                .then(res => {
                    if (res.code === '200') {
                        this.regionList = res.data || [];
                        if (!this.regionId) {
                            this.regionId = this.regionList[0]?.regionId
                            this.getBucketList(this.regionId)
                        }
                    }
                })
        },
        getBucketList (id) {
            this.$post('/bucket/list?regionId=' + id)
            .then(res => {
                if (res.code === '200') {
                    this.bucketList = res.data
                }
            })
        },
        // 选择目录 获取keys
        handleCascaderChange (val) {
            let checkedNodes = this.$refs.cascaderRef.getCheckedNodes()
            let keys = []
            console.log(checkedNodes)
            checkedNodes.forEach(item => {
                if (item.checked && (!item.parent || !item.parent.checked)) {
                    keys.push(this.handleKeysAndLabels(item))
                }
            })
            this.model.keys = keys
        },
        handleKeysAndLabels (obj) {
            if (obj.parent) {
                return this.handleKeysAndLabels(obj.parent) + '/' + obj.value
            } else {
                return obj.value
            }
        },

        // 处理三级目录
        bucketChecked (bucket) {
            this.$get(`/rfObject/bucket/${bucket}/parentKey/level/3`)
            .then(res => {
                if (res.code === '200' && res.data && res.data.length > 0 ) {
                    this.options = this.handleFileOptions(res.data)
                } else {
                    this.options = []
                }
            })
        },
        handleFileOptions (list) {
            return list.map(item => {
                let key = (Object.keys(item)[0])
                let keyV = key.substring(0, key.length - 1)
                let o = {
                    value: keyV,
                    label: keyV
                }
                if (item[key] && item[key].length > 0) {
                    o.children = this.handleFileOptions(item[key])
                }
                return o
            })
        },

        keysChecked () {
            return this.model.keys.join('\n')
        },
        show () {
            this.showDialog = true
        },
        handleClose () {
            this.showDialog = false
        },
        handleSubmit () {
            console.log(this.model.keys)
            this.$refs.form.validate((valid) => {
                if (valid) {
                    console.log(this.model)
                    this.$postJson('/rfTask/createTask', {...this.model, keys: this.model.keys.join(',')})
                    .then(res => {
                        if (res.code === '200') {
                            this.$confirm(`<div>创建离线任务成功！<br/>任务编号 <span style="color_d9001b">${res.data}</span> ，您可在离线盘匣任务列表中跟踪进度及查看详情。</div>`, {
                                title: '提示',
                                confirmButtonText: "返回列表",
                                showCancelButton: false,
                                dangerouslyUseHTMLString: true
                            }).then(res => {
                                this.showDialog = false
                                this.$emit('reload')
                            }).catch(() => {
                                this.showDialog = false
                                this.$emit('reload')
                            })
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
