<template>
    <el-dialog
        custom-class="file-info-preview" title="盘匣存储文件信息" append-to-body
        :close-on-click-modal="false"
        :visible.sync="showDialog" width="1000px" style="min-height: 528px;"
    >
        <el-form label-width="120px">
<!--            <el-row>-->
<!--                <el-col span="12"></el-col>-->
<!--                <el-col span="12"></el-col>-->
<!--            </el-row>-->
            <el-form-item  label="盘匣RFID号">{{ rfInfo.rfid }}</el-form-item>
            <el-form-item label="已用空间大小">{{ bytesToSize(rfInfo.size) }} （*仅供参考，如果文件跨盘匣存储，统计值会大于实际）</el-form-item>
            <el-form-item label="总文件数">{{ rfInfo.fileCount }}</el-form-item>
            <el-form-item label="选择离线文件占比"> {{ rfInfo.offlineSize }} </el-form-item>
        </el-form>


        <div class="search">
            <div class="left">
                <el-alert style="height: 32px;line-height: 32px;" title="黄色高亮为非用户选择离线文件" type="warning" show-icon :closable="false"></el-alert>
            </div>
            <div class="right">
                <el-input placeholder="请输入内容" v-model.trim="bucketAndKey" size="small" class="search-right">
                    <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
                </el-input>
            </div>
        </div>

        <el-table
            :data="tableData"
            v-loading="loadingData"
            :row-class-name="tableRowClassName"
            style="width: 100%" border>
            <el-table-column align="center" prop="bucketAndKey" label="文件清单" >
                <template slot-scope="{row}">
                    <div>{{ row.bucketAndKey }}</div>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="size" label="源文件大小" >
                <template slot-scope="{row}">
                    {{ bytesToSize(row.size) }}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="lastModified" label="上传时间" >
                <template slot-scope="{row}">
                    {{ row.lastModified ? moment(row.lastModified).format('YYYY-MM-DD HH:mm:ss') : '' }}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: right;"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="total">
        </el-pagination>


        <div slot="footer" class="dialog-footer">
            <el-button :loading="loading" size="small" @click="handleDownload" type="primary">下载</el-button>
        </div>

    </el-dialog>
</template>

<script>

import {bytesToSize} from "@/utils";
import moment from 'moment'

export default {
    name: "PrintPreview",
    data () {
        return {
            showDialog: false,
            loading: false,
            tableData: [],
            loadingData: false,
            currentPage: 1,
            pageSize: 5,
            total: 99,
            bucketAndKey: '',
            rfInfo: {},
            taskId: '',

        }
    },
    methods: {
        bytesToSize,
        moment,
        tableRowClassName ({row, rowIndex}) {
            if (row.matchFlag) {
                return 'offline-checked'
            }
            return  'offline-not-checked'
        },
        show (rfInfo = {}, id = '') {
            this.rfInfo = rfInfo
            this.showDialog = true
            this.taskId = id
            this.getData()
            this.getRFInfo()
        },
        getRFInfo () {
            this.$get(`/rfTask/rf?rfid=${this.rfInfo.rfid}&taskId=${this.taskId}`)
            .then(res => {
                if (res.code === '200') {
                    this.rfInfo = {...this.rfInfo, ...res.data}
                }
            })
        },
        handleSearch () {
            this.currentPage = 1
            this.getData()
        },
        getData () {
            const {currentPage, bucketAndKey, taskId, rfInfo,pageSize } = this
            /**
             *  "pageNum": 0,
             *   "pageSize": 0,
             *   "bucketAndKey": "string",
             *   "rfid": "string",
             *   "taskId": "string"
             */
            let params = {
                pageNum: currentPage,
                pageSize,
                bucketAndKey,
                rfid: rfInfo.rfid,
                taskId
            }

            this.$postJson('/rfObject/pageObject', params)
            .then(res => {
                if (res.code === '200') {
                    this.tableData = res.data.records
                    this.total = res.data.total
                }
            })

        },
        handleClose () {
            this.showDialog = false
        },
        handleCurrentChange (current) {
            this.currentPage = current
            this.getData()
        },
        handleDownload () {
            console.log('下载1111')
            this.$emit('downloadFile', this.rfInfo)
        }
    }
}
</script>

<style scoped lang="scss">

.search {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    .left {
        flex: 2;
        padding-right: 10px;
    }
    .search-right {
        flex: 1;
    }
}

.checked {
    background: #ff8700;
    color: #fff;
}

</style>