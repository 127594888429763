<template>
    <div class="offline-cassette">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>离线盘匣管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="cassette-page">
            <div class="cassette-opts">
                <div>
                    <OptButton size="small" type="primary" class="_create" @click="handleApply(1)"
                               v-if="accountType === 'main'">申请上线
                    </OptButton>
                    <OptButton size="small" type="primary" class="_create" @click="handleApply(2)"
                               v-if="accountType === 'main'">申请离线
                    </OptButton>
                    <OptButton size="small" type="primary" class="_create" @click="toTaskList"
                               v-if="accountType === 'main'">任务列表
                    </OptButton>
                    <OptButton size="small" icon="icon iconfont iconbianzu1711"
                               style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                               @click="loadData(true)"></OptButton>
                </div>
                <div style="display: flex;">
                    <el-select
                        v-model="lineStatus"
                        clearable
                        placeholder="选择盘匣状态"
                        size="200px"
                        @change="taskStatusChange"
                    >
                        <el-option
                            v-for="item in taskStatusList"
                            :value="item.value"
                            :label="item.label"
                            :key="item.value"/>
                    </el-select>
                    <SpaceItem></SpaceItem>
                    <el-select
                        v-model="searchType"
                        placeholder="选择任务状态"
                        style="flex-basis: 140px;flex-shrink: 0;flex-grow: 0"
                        @change="taskStatusChange"
                    >
                        <el-option
                            v-for="item in searchTypeList"
                            :value="item.value"
                            :label="item.label"
                            :key="item.value"/>
                    </el-select>
                    <SpaceItem></SpaceItem>
                    <el-input placeholder="请输入"  v-model="keyword" style="width: 200px;" class="input-with-select" >
                        <el-button slot="append" icon="el-icon-search" @click="loadData"></el-button>
                    </el-input>


                </div>
            </div>
            <div  class="table-container" >
                <el-table ref="listTable" v-loading="loadingData" :data="tableData" style="width: 100%;" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="45px"></el-table-column>
                    <el-table-column prop="taskName" label="离线任务名称"></el-table-column>
                    <el-table-column prop="rfid" label="盘匣RFID号"></el-table-column>
                    <el-table-column prop="das" label="所属盘库"></el-table-column>
                    <el-table-column prop="lastOfflineTime" label="最新离线时间">
                        <template slot-scope="{row}">
                            {{ row.lastOfflineTime ? moment(row.lastOfflineTime).format('YYYY-MM-DD HH:mm:ss') : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="storePosition" label="存储位置"></el-table-column>
                    <el-table-column prop="lastOnlineTime" label="最新上线时间">
                        <template slot-scope="{row}">
                            {{ row.lastOnlineTime ? moment(row.lastOnlineTime).format('YYYY-MM-DD HH:mm:ss') : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="files" label="文件清单" >
                        <template slot-scope="{row}">
                            <el-button type="text" @click="fileInfoPreview(row)">预览</el-button>
                            <el-button type="text" @click="handleFileDownload(row)">下载</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lineStatus" label="盘匣状态">
                        <template slot-scope="{row}"> {{ $RfStatusList[row.lineStatus] || '' }} </template>
                    </el-table-column>
                    <el-table-column prop="action" label="操作">
                        <template slot-scope="{$index, row}">
                            <div style="display: flex;justify-content: flex-start;">
                                <span class="table-action-button" @click="showDetail(row)">日志</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20, 50, 100]"
                       @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>
            </div>
        </div>
        <FileInfo ref="fileInfoRef" @downloadFile="handleFileDownload"/>
        <LogInfo ref="logInfoRef" />
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import TaskForm from "@/views/offline/components/TaskForm";
import FileInfo from "./components/FIleInfo";
import moment from 'moment'
import LogInfo from './components/Log'
import {downloadZip} from "@/utils";
import {downloadFilesOfRF} from "@/views/offline/js";

export default {
    name: "task",
    components: {FileInfo, LogInfo},
    data() {
        return {
            loadingData: false,
            tableData: [

            ],
            pager: {
                current: 1,
                size: 10,
                total: 0
            },
            searchType: 'bucketAndKey',
            searchTypeList: [
                {value: 'taskName', label: '按任务名称搜索'},
                {value: 'rfid', label: '按盘匣RFID搜索'},
                {value: 'bucketAndKey', label: '按文件KEY搜索'},
            ],
            keyword: '',
            lineStatus: '',
            taskStatusList: [
                {label: '离线', value: 'OFFLINE'},
                {label: '上线中', value: 'ONLINEING'},
                {label: '离线中', value: 'OFFLINEING'},
                {label: '上线', value: 'ONLINE'},
            ],
            selectedRow: []
        }
    },
    computed: {
        ...mapGetters(['accountType'])
    },
    mounted() {
        this.loadData()
    },
    methods: {
        moment,
        toTaskList () {
            this.$router.push('/octl')
        },
        handleSelectionChange(e) {
            this.selectedRow = [...e];
        },
        handleApply(type) { // type 1上线  2离线
            const {selectedRow} = this;
            if (this.selectedRow.length === 0) {
                this.$message.warning('请至少选择一个任务')
                return
            }

            // `
            let status = selectedRow.map(item => item.lineStatus)

            if (status.indexOf('ONLINEING') > -1 || status.indexOf('OFFLINEING') > -1) {
                this.$message.warning('任务进行中的盘匣不能操作')
                return
            }

            if (type === 1 && status.indexOf('ONLINE') > -1) {
                this.$message.warning('请选择离线状态的盘匣')
                return
            }
            if (type === 2 && status.indexOf('OFFLINE') > -1) {
                this.$message.warning('请选择上线状态的盘匣')
                return
            }

            this.$confirm(`确认申请${type === 1 ? '上线': '离线'}吗！`, {
                confirmButtonText: "确定",
                type: "warning",
                showClose: false,
                closeOnClickModal: false
            }).then(res => {
                let data = {
                    rfids: selectedRow.map(item => item.rfid),
                    lineStatus: type === 1 ? 'ONLINEING' : 'OFFLINEING'
                }
                this.$postJson('/rf/lineStatus', data)
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功')
                        this.loadData()
                    }
                })
            })
        },
        loadData() {
            const {searchType, keyword, lineStatus} = this
            let data = {pageNum: this.pager.current, pageSize: 10}
            if (keyword) {
                data[searchType] = keyword
            }
            if (lineStatus) {
                data.lineStatus = lineStatus
            }
            this.loadingData = true
            this.$postJson('/rf/page', data)
            .then(res => {
                if (res.code === '200') {
                    this.tableData = res.data.records
                    this.pager.total = res.data.total
                }
            })
            .finally(() => {
                this.loadingData = false
            })
        },
		handleSizeChange(size) {
			this.pager.current = 1;
			this.pager.size = size;
			this.loadData();
		},
		handleCurrentChange(current) {
			this.pager.current = current
			this.loadData();
		},
        searchLog () {},
        taskStatusChange () {},
        showDetail (t) {
            // 查看日志
            this.$refs.logInfoRef.show(t);
        },
        // 文件预览
        fileInfoPreview (r) {
            this.$refs.fileInfoRef.show(r, r.taskId)
        },
        handleFileDownload (row) {
            downloadFilesOfRF(row.rfid, row.taskId)
            // this.$postJson(`/rfObject/download/${row.rfid}/${row.taskId}`, {})
            // .then(res => {
            //     downloadZip(res, '文件清单.zip')
            // })
        }
    }
}
</script>

<style scoped>
.offline-cassette {
    margin: 16px 16px 10px;
    padding: 0 26px;
    background: #fff;
    text-align: left;
    min-height: calc(100% - 36px);
}

.cassette-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
}
</style>
