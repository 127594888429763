import service from '@/utils/request'
import {downloadZip} from "@/utils";
export const taskStatusDesc = (step, status) => {
    const list = [
        {label: '定位数据所在盘匣', value: 'POSITION_DATA'},
        {label: '确定离线盘匣', value: 'CONFIRM_RF'},
        {label: '标签打印', value: 'LABEL_PRINT'},
        {label: '取出盘匣', value: 'OFFLINE_RF'},
        {label: '录入存储位置', value: 'STORE_POSITION'},
        {label: '任务完成', value: 'COMPLETE'},
        {label: '任务取消', value: 'CANCEL'},
    ]
    return status === 'CANCEL' ? '任务取消' : (list.find(i => i.value === step)?.label || '')
}

export const taskStatusList = [
    {label: '任务取消', value: 'CANCEL'},
    {label: '定位数据所在盘匣', value: 'POSITION_DATA'},
    {label: '确定离线盘匣', value: 'CONFIRM_RF'},
    {label: '标签打印', value: 'LABEL_PRINT'},
    {label: '取出盘匣', value: 'OFFLINE_RF'},
    {label: '录入存储位置', value: 'STORE_POSITION'},
    {label: '任务完成', value: 'COMPLETE'},
]

const getDownloadFileName = (res) => {
    let a = res.headers['content-disposition'].split('filename=')
    if (a.length === 2) {
        return window.decodeURIComponent(a[1])
    }
    return  ''
}

// 导出盘匣内的文件
const downloadFilesOfRF = (rfid, taskId, name) => {
    service.postJson(`/rfObject/download/${rfid}/${taskId}`, {})
        .then(res => {
            downloadZip(res.data, getDownloadFileName(res) || '文件清单.zip')
        })
}

export  {
    downloadFilesOfRF,
    getDownloadFileName
}

